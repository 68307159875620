/* You can add global styles to this file, and also import other style files */

// Global icon font
@import "./assets/fonts/feather/style.css";

// // 3rd party plugins
@import "@mdi/font/css/materialdesignicons.min.css";
@import "perfect-scrollbar/css/perfect-scrollbar.css";
@import "highlight.js/styles/googlecode.css";
@import "ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";
@import "animate.css/animate.min.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "metismenujs/scss/metismenujs";
@import "dropzone/dist/min/dropzone.min.css";
@import "quill/dist/quill.snow.css";
@import "angular-archwizard/styles/archwizard.css";
@import "simple-datatables/dist/style.css";
@import "@swimlane/ngx-datatable/index.css";
@import "@swimlane/ngx-datatable/themes/bootstrap.css";
@import "@swimlane/ngx-datatable/assets/icons.css";

// NobleUI styles for LTR
@import "./assets/scss/style";


// NobleUI styles For RTL
// @import "./assets/scss/style.rtl.css";

.main-content {
  font-size: 14px !important;
}

.content-nav-wrapper-mobile {
  display: none;
  @media(max-width: 1199px) {
    display: block;
  }
}

.img-form {
  max-width: 150px;
  max-height: 150px;
}

.page-breadcrumb {
  display: flex;
  justify-content: space-between;
}

.w-80 {
  width: 80% !important;
}

#googleMap {
  // margin-top: 30px;
  width: 100% !important;
  height: 15rem !important;
}

.border-2 {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.edit-icon {
  position: absolute;
  top: 0;
  //right: 0;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.edit-icon i {
  color: #007bff;
}

.edit-icon {
  position: relative;
  display: inline-block;
}

.edit-icon i {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.text-muted, .dropzone.dz-clickable .dz-message * {
  color: #515acc !important;
}

//.sidebar .sidebar-header .sidebar-brand img {
//  height: 5.5rem !important;
//}

.sidebar .sidebar-header .sidebar-brand img {
  height: 3.5rem !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay app-loading-animation {
  width: 50px; /* Ajusta el tamaño de la animación de carga según sea necesario */
  height: 50px;
}

.bg-form {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
}

.main-wrapper .page-wrapper .page-content {
  flex-grow: 1;
  padding: 1em;
  margin-top: 60px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

/* Agrega una animación de carga para la capa de animación de carga */
.loading-overlay:after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #0099ff;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.grid-margin {
  margin-bottom: 0.5rem;
}

#dataTableExample td.text-center {
  text-align: center;
}

.bg-lightblue {
  background-color: lightblue;
}

.disabled-overlay:after {
  pointer-events: none; /* Desactiva la interacción del mouse con el overlay */
  display: none !important;
}
